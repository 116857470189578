// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--25c45";
export var animateScrollOut = "styles-module--animateScrollOut--9677a";
export var colorSequence = "styles-module--colorSequence--e2a83";
export var container = "styles-module--container--09b1c";
export var contentContainer = "styles-module--contentContainer--5973b";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--aa210";
export var floatingAcademyStyles = "styles-module--floatingAcademyStyles--230b3";
export var grow = "styles-module--grow--01ab5";
export var growAndShrink = "styles-module--growAndShrink--5466d";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--83cb0";
export var htmlContainer = "styles-module--htmlContainer--c6fc2";
export var imageContainer = "styles-module--imageContainer--6765e";
export var imgContainer = "styles-module--imgContainer--79677";
export var moveBg = "styles-module--move-bg--2a83a";
export var rotateLoop = "styles-module--rotateLoop--74323";
export var section = "styles-module--section--65135";
export var spin = "styles-module--spin--61fd0";
export var spinCounter = "styles-module--spinCounter--f6c56";