import React from 'react';
import { Section, Image, Link } from 'components';
import * as styles from './styles.module.scss';
import FloatingAcademy from '../../components/SliceHelpers/FloatingAcademy';

const BannerWithLargeImage = ({ data }) => {
  const {
    primary: {
      title1: title,
      logo,
      large_image: largeImage,
      description,
      cta_text: ctaText,
      cta_url: ctaUrl,
      animated_image: animatedImage,
    },
  } = data;
  return (
    <Section
      className={`${styles.section} ${
        animatedImage === 'Floating Academy' ? styles.floatingAcademyStyles : ''
      }`}
      containerClassName={styles.container}
    >
      <div className={styles.imageContainer}>
        {animatedImage === 'Floating Academy' ? (
          <FloatingAcademy />
        ) : (
          <Image className={styles.imgContainer} image={largeImage} />
        )}
      </div>
      <div className={styles.contentContainer}>
        <Image className={styles.imgContainer} image={logo} />
        <h3>{title.text}</h3>
        <div
          className={styles.htmlContainer}
          dangerouslySetInnerHTML={{ __html: description.html }}
        />
        <Link className="button" to={ctaUrl.url}>
          {ctaText.text}
        </Link>
      </div>
    </Section>
  );
};

export default BannerWithLargeImage;
